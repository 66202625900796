.bigimage-container {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: nth($shadows, 4);
  padding: 0;
  display: flex;
  margin-left: 40px;
  @media screen and (max-width: 768px) {
    margin-left: 5%;
    margin-right: 5%;
    height: 250px; }

  & > img {
    object-fit: cover;
    width: 100%;
    height: auto; } }

.full-width-image {
  @supports (object-fit: cover) {
    object-fit: cover;
    width: 100%; } }
