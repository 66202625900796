.card {
  box-shadow: nth($shadows, 3);
  border-radius: 10px;
  position: relative;
  background: #fbfbfb;
  margin: 0 auto;
  max-width: 750px;
  @media screen and (max-width: 768px) {
    max-width: 95%; }
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2.card-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.1;
      margin: 0 0 1rem;
      color: $black; }
    p.card-text {
      font-size: 14px; } }

  &.intro-card {
    max-width: 740px;
    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr 2fr; }
    margin: 0 auto;
    margin-top: -7%;
    z-index: 10;
    @media screen and (max-width: 1024px) {
      margin-top: -10vh; }
    @media screen and (max-width: 768px) {
      max-width: 95%; }
    @media screen and (max-width: 450px) {
      margin-top: -25vw;
      @supports (display: grid) {
        grid-template-columns: 1fr; } }

    .card-image-container {
      display: flex;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      overflow: hidden;
      @media screen and (max-width: 768px) {
        height: 150px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: unset; }
      & > img {
        max-width: 100%;
        height: auto;
        @supports (object-fit: cover) {
          object-fit: cover;
          width: 100%;
          height: 100%; } } }

    .card-content {
      p.card-text {
        margin-bottom: 2rem; } }

    .main-btn {
      position: absolute;
      bottom: 0;
      right: 30px;
      transform: translateY(50%);
      transition: $main-transition;
      &:hover {
        transform: translateY(45%); }
      @media screen and (max-width: 450px) {
        right: 50%;
        transform: translateY(50%) translateX(50%);
        &:hover {
          transform: translateY(45%) translateX(50%); } } }

    .card-overlay {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transition: $main-transition;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $white;
      border-radius: 10px;
      transform: scale(0.75);
      &.active {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
        transform: scale(1); }

      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 1.5em;
        transition: $main-transition;
        opacity: 0.5;
        z-index: 10;
        &:hover {
          cursor: pointer;
          opacity: 1; } }


      .columns {
        height: 100%;
        transform: translateY(10px);

        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column; } }

      .column {
        display: flex;
        position: relative;
        &:not(:last-child) {
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 10px;
            width: 1px;
            background-color: transparentize($black, 0.75);
            height: 25%;
            transform: translateY(-50%);
            z-index: 5;
            @media screen and (max-width: 768px) {
              top: unset;
              bottom: -10px;
              right: 50%;
              transform: translateX(50%);
              width: 25%;
              height: 1px; } } } }

      .app-link {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        &.dissabled {
          opacity: 0.5;
          //filter: grayscale(1)
          pointer-events: none; }
        svg {
          width: 25%;
          height: auto; }
        span {
          color: $black;
          font-size: 1.4em;
          font-weight: 700;
          margin-top: .5em; } } } }


  &.blogpost-card {
    padding: 0;
    margin: 0 1rem;
    transition: $main-transition;
    padding-bottom: 30px;
    flex: 1;
    height: 100%;
    @media screen and (max-width: 1024px) {
      margin: 0 0.75rem; }
    @media screen and (max-width: 768px) {
      margin: 1rem 1rem 3rem; }
    &:hover {
      transform: translateY(-5px);
      box-shadow: nth($shadows, 5); }

    .card-image {
      display: flex;
      overflow: hidden;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      max-height: 150px;
      & > img {
        max-width: 100%;
        height: auto;
        @supports (object-fit: cover) {
          object-fit: cover;
          width: 100%;
          height: 100%; } } }

    .card-content {
      padding: 20px 20px 0;

      h3.card-title {
        font-size: 18px;
        font-weight: 700;
        color: $black;
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }

      .card-meta {
        & > span {
          font-size: 12px;
          font-weight: 200;
          font-style: italic;
          margin: 0 0.5rem;
          &:first-child {
            margin-left: 0; }
          &:last-child {
            margin-right: 0; } } }

      .card-excerpt {
        margin-top: 15px;
        font-size: 14px;
        line-height: 1.618;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }

      .cta-container {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        .main-btn {
          font-size: 14px;
          transform: translateY(50%);
          &:hover {
            transform: translateY(50%); } } } } }

  &.how-it-works-card {
    overflow: hidden;
    min-height: 180px;
    width: calc(50% - 40px);
    box-shadow: nth($shadows, 1);
    margin: unset;
    @media screen and (max-width: 768px) {
      width: 90%;
      margin-left: auto;
      height: auto; }

    &:nth-of-type(odd) {
      margin-left: auto; }

    .card-content {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1rem 1rem 1rem 5rem;
      height: 100%;
      @media screen and (max-width: 768px) {
        padding: 1rem; }
      h4.card-title {
        font-size: 24px;
        font-weight: 700;
        color: $black; }

      p.card-text {
        line-height: 1.4;
        margin-top: 10px; } }

    .card-icon {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(33%) translateX(-33%);
      opacity: .25;
      height: 100%; } } }
