section#post-wireframe {
  padding: 0;

  div {
    border-radius: 5px; }

  header {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 250px;
    background: transparentize($black, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .wf-post-title {
      width: 250px;
      height: 20px;
      background: transparentize($white, 0.35);
      border-radius: 5px;
      margin-top: 2rem; }

    .wf-post-meta {
      width: 100px;
      height: 15px;
      background: transparentize($white, 0.5);
      margin-top: 1rem;
      border-radius: 5px; } }

  article {
    padding: 1rem;
    height: 100vh;

    .wf-post-subtitle {
      width: 400px;
      height: 20px;
      background: transparentize($black, 0.15); }

    .wf-post-text-container {
      margin: 1rem 0;
      padding: 0 1rem;
      .wf-post-text {
        width: 100%;
        background: transparentize($black, 0.8);
        height: 10px;
        margin: 10px 0; } }

    .wf-post-image {
      display: block;
      margin: 2rem auto;
      width: 50%;
      height: 250px;
      background: transparentize($black, 0.5); } } }




