.team-member {
  box-shadow: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-bottom: 1em; }

  figure {
    overflow: hidden;
    &.is-rounded {
      border-radius: 50%; }

    img {
      object-fit: cover;
      height: 100%; } }

  .content {
    margin-top: 1em; } }
