$footer-height: 280px;

.not-found-page {
  min-height: calc(100vh - #{$footer-height});

  .hero.subpage-hero {
    max-height: 150px;
  }

  .subpage-section {
    padding: 124px 0;
  }

  .section-image {
    margin: 0 auto 32px;
    display: block;
    height: 240px;
    width: auto;
    object-fit: contain;
  }

  .cta-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }
}