.single-blog-post {
  .subpage-hero {
    max-height: 480px;

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
      padding-bottom: 1.5rem;
      max-height: 300px; }

    .hero-body {
      h1.title {
        line-height: 1.2;
        font-size: 42px; } }

    .single-blog-post-meta {
      span {
        margin: 0 0.5em;

        &:first-child {
          margin-left: 0; }

        &:last-child {
          margin-right: 0; } } } } }

#single-post {
  padding-top: 20px;
  .blog-post-container {
    max-width: 750px;
    padding: 0 1rem;

    h1, h2, h3, h4, h5 {
      margin: 2rem 0 1rem;
      color: $black;
      font-weight: 700; }

    p {
      line-height: 1.618;
      text-align: justify;
      margin: 0.5rem 0;

      a {
        color: $primary;
        &:hover {
          color: $black; } }

      em {
        margin-top: 1rem;
        display: inline-block;
        & + img {
          margin-top: 0.5rem !important; } } }

    blockquote {
      background-color: transparentize($primary, 0.8);
      color: $primary;
      border-color: $primary;

      p {
        text-align: left; }

      strong {
        color: $primary; } }

    img {
      margin: 2.5rem -1.5rem 3.5rem;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: nth($shadows, 3);
      display: flex;
      max-height: 300px;
      max-width: unset;
      height: auto;
      object-fit: cover;
      width: calc(100% + 3rem);
      height: 100%;
      @media screen and (max-width: 768px) {
        margin: 1.5rem 0 3.5rem; }
      &[src$='#vertical'] {
        max-height: 450px;
        margin: 2.5rem auto 3.5rem;
        width: auto;
        max-width: 100%; } }

    .cta-container {
      display: flex;
      justify-content: center;
      margin-top: 3rem;

      & > *:not(:first-child) {
        margin-left: 1em; }

      @media screen and (max-width: 768px) {
        margin-top: 1.5rem; } } } }

