.contact-form {
  .container {
    max-width: 750px;
    margin: 0 auto; }

  form {
    margin-top: 3rem; }

  input, textarea {
    background: #fbfbfb;
    border-radius: 10px;
    transition: 200ms ease-in-out all;
    &:active,
    &:focus {
      border-color: $green;
      box-shadow: none; } }

  #submit-btn {
    border-radius: 20px;
    line-height: 2px; }

  .cta-container {
    display: flex;
    justify-content: center; }

  .success-message-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      padding: 0 1rem; }
    .success-message {
      color: $green;
      font-weight: 700;
      font-size: 1.2em;
      @media screen and (max-width: 768px) {
        text-align: center; } }
    a.is-small {
      font-size: 0.8em;
      color: $black;
      @media screen and (max-width: 768px) {
        margin-top: 1rem; } } } }
