#page-nav {
  padding: 12px;

  .navbar-end {
    align-items: center;

    .main-btn {
      font-size: 14px;
      text-transform: none;
    }
  }
}