@import url('https://fonts.googleapis.com/css?family=Merriweather+Sans:300,400,700');

// Globals
@import '01-Globals/Normalize';
@import 'Bulma';
@import '00-FontAwesome/fontawesome';
@import '01-Globals/Overrides';

// Variables
@import '02-Variables/Colors';
@import '02-Variables/Fonts';
@import '02-Variables/Shadows';
@import '02-Variables/Transitions';
@import '02-Variables/Clippaths';

// Components
@import '03-Components/Buttons';
@import '03-Components/Navbar';
@import '03-Components/Hero';
@import '03-Components/Cards';
@import '03-Components/Section';
@import '03-Components/Images';
@import '03-Components/ContactForm';
@import '03-Components/LevelItem';
@import '03-Components/TeamMember';
@import '03-Components/Footer';

// Screens
@import '04-Screens/Home';
@import '04-Screens/Subpage';
@import '04-Screens/About';
@import '04-Screens/Services';
@import '04-Screens/Contact';
@import '04-Screens/Blog';
@import '04-Screens/BlogSinglePost';
@import '04-Screens/PostWireframe';

p {
  line-height: 1.618; }

.v-align-center {
  display: inline-flex;
  align-items: center; }

.h-align-end {
  display: inline-flex;
  justify-content: flex-end; }
