.services-page {
  .subpage-main-content {
    @media screen and (max-width: 768px) {
      transform: translateY(-75px); } } }
.services-container {
  margin-top: 75px;
  max-width: 900px;
  @media screen and (max-width: 768px) {
    margin-left: 1rem;
    margin-right: 1rem; }

  .single-service {
    display: flex;
    margin: 100px 0;
    @media screen and (max-width: 768px) {
      flex-direction: column; }
    &:nth-of-type(even) {
      flex-direction: row-reverse;
      @media screen and (max-width: 768px) {
        flex-direction: column; }
      .service-icon {
        margin-right: 0;
        margin-left: 30px;
        @media screen and (max-width: 768px) {
          margin-left: unset; }
        & > img {
          transform: translateX(20px);
          @media screen and (max-width: 768px) {
            transform: translateY(20px); } }
        &::before {
          right: 0;
          left: unset;
          background: $brown; } } }

    .service-icon {
      overflow: hidden;
      max-height: 200px;
      display: flex;
      align-items: center;
      margin-right: 30px;
      position: relative;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-right: unset;
        margin-bottom: 2rem; }
      & > img {
        transform: translateX(-20px);
        @media screen and (max-width: 768px) {
          transform: translateY(20px); } }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 10px;
        background: $green;
        border-radius: 10px;
        @media screen and (max-width: 768px) {
          top: unset;
          bottom: 0;
          left: 0;
          right: 0;
          height: 10px;
          width: 100%; } } }

    .service-content {
      flex: 1;
      .service-title {
        color: $black;
        font-size: 24px;
        font-weight: 700;
        @media screen and (max-width: 768px) {
          font-size: 20px; } }
      .service-lead-text {
        font-size: 20px;
        margin: 0.5em 0;
        @media screen and (max-width: 768px) {
          font-size: 18px; } }
      .service-description {
        font-size: 14px;
        text-align: justify;
        max-width: 80%;
        margin-bottom: 0.75em;
        line-height: 1.618;
        @media screen and (max-width: 768px) {
          max-width: unset; } } } } }
