.contact-subpage {
  .subpage-main-content {
    transform: translateY(-75px); }
  iframe {
    box-shadow: nth($shadows, 4);
    overflow: hidden;
    border-radius: 10px;
    max-width: 900px;
    margin: 0 auto;
    display: block;
    @media screen and (max-width: 1024px) {
      max-width: 90%; } }

  .contact-informations {
    margin-top: 25px;
    @media screen and (max-width: 768px) {
      margin-bottom: 50px;
      .level-item {
        margin-bottom: 1.5rem; } } } }
