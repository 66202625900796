.subpage-main-content {
  transform: translateY(-75px);
  @media screen and (max-width: 768px) {
    transform: translateY(-50px); }

  .card.section-intro-card {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column; }
    .card-image-container {
      display: flex;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      overflow: hidden;
      @media screen and (max-width: 768px) {
        border-bottom-left-radius: unset;
        border-top-right-radius: 10px; }
      & > img {
        max-width: 100%;
        height: auto;
        @supports (object-fit: cover) {
          object-fit: cover;
          width: 100%;
          height: 100%; } } } } }
