.is-sl {
  .hero {
    &.subpage-hero {
      .hero-body {
        h1.title {
          text-transform: unset; } } } } }

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 650px;
  overflow: hidden;
  position: relative;

  &.subpage-hero {
    max-height: 350px;
    background: $white;
    overflow: visible;
    @media screen and (max-width: 768px) {
      max-height: 250px;
      margin-bottom: 40px; }

    .container {
      padding-bottom: 3rem;
      @media screen and (max-width: 768px) {
        padding-bottom: 0; } }

    .hero-bg {
      background: {
        image: url('../../assets/subpage-hero-bg.jpeg');
        position: center bottom;
        size: cover; }
      @include cp-downwards(65%);
      @media screen and (max-width: 768px) {
        @include cp-downwards(85%); }

      .overlay {
        background: transparentize($black, 0.15); } }

    .hero-body {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0;
      margin-top: 0;
      padding: 0;
      flex-direction: column;
      h1.title {
        font-size: 64px;
        font-weight: 700;
        color: $white;
        text-transform: capitalize;
        padding-top: 2rem;
        @media screen and (max-width: 768px) {
          font-size: 48px;
          text-align: center;
          padding-top: 0; }
        @media screen and (max-width: 450px) {
          font-size: 36px; } }
      h2.subtitle {
        color: $white;
        @media screen and (max-width: 768px) {
          font-size: 16px;
          text-align: center; } }
      .single-blog-post-meta {
        color: $white; } } }

  .hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    //overflow: hidden

    .full-width-image {
      width: 100%;
      height: 100%;
      object-fit: cover; }

    .overlay {
      position: absolute;
      background: transparentize($black, 0.15);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; } }

  .hero-body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h1.title {
        font-size: 54px;
        color: $white;
        max-width: 650px;
        text-align: center;
        @media screen and (max-width: 500px) {
          font-size: 42px; } }

      p.tagline {
        color: $white;
        font-size: 14px;
        max-width: 500px;
        text-align: center; }

      .hero-cta-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1em;
        margin-top: 35px; } } } }
