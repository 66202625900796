$footer-min-height: 300px;
footer {
  min-height: $footer-min-height;
  background: $black;
  padding: 0 0 1rem;
  width: 100%;
  .scroll-top {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    background: transparentize($white, 0.85);
    margin-bottom: 1rem;
    .fas {
      font-size: 36px;
      color: $white; } }
  .container {
    .columns {
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: unset;
        margin-right: unset;
        .column {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; } } } }

  .footer-logo {
    max-width: 250px;
    margin-top: 1rem; }

  .footer--badges-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 190px; } } }



  .footer-contact-info-container {
    margin: 2.5rem 0;
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column; }
    .contact-info-item {
      margin: 0 1.5rem;
      @media screen and (max-width: 768px) {
        margin: 1rem 0; }
      &:first-of-type {
        margin-left: 0; }
      color: $white;
      .fas {
        margin-right: 10px; } } }

  .footer-divider-container {
    display: flex;
    justify-content: center;
    .footer-divider {
      width: 2px;
      height: 100%;
      min-height: calc(#{$footer-min-height} - 4rem);
      background: transparentize($white, 0.35); } }

  .footer-legal-info {
    width: 100%;
    display: flex;
    justify-content: center;
    color: transparentize($white, 0.35);
    font-size: 12px; } }
