section.intro-card-container {
  //background: transparentize($brown, 0.85)
  // padding-bottom: 0
  margin-bottom: 10px; }

section#services {
  h2.section-title {
    text-align: center; }

  .columns {
    margin-top: 30px;
    @media screen and (max-width: 1024px) {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto; } }

  .service-item {
    .service-icon-container {
      display: flex;
      justify-content: center;
      position: relative;
      overflow: hidden;
      & > img {
        transition: $main-transition;
        transform: translateY(25%); }
      .bottom-border {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 10px;
        background: $green;
        border-radius: 10px; } }

    h3.service-item-title {
      margin: 1rem 0;
      font-size: 18px;
      color: $black;
      font-weight: 700;
      max-width: 250px;
      @media screen and (max-width: 768px) {
        max-width: unset; } }

    p.service-item-excerpt {
      font-size: 13px;
      line-height: 1.618; }

    &:nth-child(even) {
      .service-icon-container {
        .bottom-border {
          background: $brown; } } }

    &:hover {
      .service-icon-container {
        & > img {
          transform: translateY(10%); } } } }

  .section-cta-container {
    margin-top: 75px;
    @media screen and (max-width: 768px) {
      margin-top: 40px; } } }

section#contact {
  margin-top: 25px;
  padding-bottom: 75px;

  .columns {
    @media screen and (min-width: 1400px) {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto; }
    @media screen and (max-width: 450px) {
      width: 100%;
      margin-left: unset;
      margin-right: unset; }

    .section-content {
      padding-left: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 768px) {
        padding-left: unset;
        padding-right: unset;
        margin-left: 7.5%;
        margin-right: 7.5%; }

      p.section-excerpt {
        margin-left: unset;
        margin-right: unset;
        @media screen and (max-width: 1024px) {
          padding-right: 1em; }
        @media screen and (max-width: 768px) {
          max-width: unset; } }

      .section-cta-container {
        margin-top: 40px; } } } }

section#blog {
  background: transparentize($brown, 0.85);
  margin-top: 125px;
  padding-bottom: 125px;
  padding-top: 25px;

  h2.section-title {
    text-align: center; }

  h3.section-subtitle {
    text-align: center; }

  .blogposts {
    margin-top: 40px;
    @media screen and (max-width: 1024px) {
      max-width: 95%;
      margin-left: auto;
      margin-right: auto; } } }

section#home-quote {
  padding: 0;
  height: 350px;
  display: flex;

  .container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    h4.quote {
      color: $white;
      font-weight: 200;
      font-size: 36px;
      max-width: 520px;
      text-align: center;
      position: relative;
      @media screen and (max-width: 550px) {
        font-size: 28px;
        max-width: 90%; }
      &::before {
        content: '“';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 144px;
        font-weight: 700;
        font-family: 'Merriweather Sans', sans-serif;
        transform: translateY(-95px);
        @media screen and (max-width: 550px) {
          font-size: 100px;
          transform: translateY(-65px);
          left: 10px; } } } } }

section#how-it-works {
  margin-top: 25px;

  .container {
    @media screen and (max-width: 1024px) {
      max-width: 95%;
      margin-left: auto;
      margin-right: auto; } }

  .steps-container {
    margin-top: 40px;
    position: relative;

    .timeline {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 8px;
      left: 50%;
      margin-left: -4px;
      background: $green;
      border-radius: 20px;
      @media screen and (max-width: 768px) {
        left: 10px; }
      .timeline-items-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 8px;
        left: 50%;
        margin-left: -4px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .timeline-item {
          width: 25px;
          height: 25px;
          border-radius: 50px;
          background: $green;
          transform: translateX(calc(-50% + 4px));
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          &::after {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            border-radius: 20px;
            background: $green;
            border: 3px solid $white;
            // top: 50%
            // left: 50%
            // position: absolute
 } } } }            // transform: translateY(-50%) translateX(-50%)

    .how-it-works-card {
      margin-top: 1rem;
      margin-bottom: 1rem; } }

  .section-cta-container {
    margin-top: 40px;
    @media screen and (max-width: 768px) {
      margin-bottom: 40px; } } }
