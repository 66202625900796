section,
.subpage-section {
  padding: 50px 0;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 25px 0; }

  .section-starter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @include cp-upwards;
    height: 75px;
    background: transparentize($brown, 0.85);
    transform: translateY(-100%); }

  .section-ender {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    @include cp-downwards;
    height: 75px;
    background: transparentize($brown, 0.85);
    transform: translateY(100%); }

  h2.section-title {
    font-size: 36px;
    font-weight: 700;
    color: $black;
    margin: 1rem 0 0;
    text-transform: lowercase;
    &.is-centered {
      text-align: center; }
    @media screen and (max-width: 450px) {
      font-size: 32px; } }

  h3.section-subtitle {
    font-size: 36px;
    font-weight: 200;
    color: $black;
    text-transform: lowercase;
    &.is-centered {
      text-align: center; }
    @media screen and (max-width: 450px) {
      font-size: 28px;
      &.has-padding {
        padding: 0 1rem; } } }

  p.section-excerpt {
    font-size: 14px;
    max-width: 500px;
    text-align: justify;
    line-height: 1.618;
    margin: 1rem auto;
    @media screen and (max-width: 550px) {
      max-width: 95%; } }

  .section-cta-container {
    display: flex;
    align-items: center;
    &.is-centered {
      justify-content: center; } } }

.subpage-section {
  margin: 4rem 0;
  @media screen and (max-width: 768px) {
    margin: 2rem 0; }
  &.contact-form {
    margin-top: -5rem; }
  &:first-of-type {
    margin-top: 0; }
  &:nth-child(even) {
    .section-columns {
      flex-direction: row-reverse;
      @media screen and (max-width: 768px) {
        flex-direction: column-reverse; } } }

  .section-featured-image {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: nth($shadows, 4);
    display: flex;
    padding: 0;
    @media screen and (max-width: 768px) {
      margin-left: 1rem;
      margin-right: 1rem; }
    & > img {
      object-fit: cover;
      width: 100%;
      height: 100%; } }

  .section-content {
    padding: 40px 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 768px) {
      padding: 20px 1rem; }
    .section-intro-paragraph {
      margin: 1rem 0;
      .intro-paragraph-content {
        position: relative;
        padding: 0.5rem 0 0.5rem 25px;
        font-size: 18px;
        line-height: 1.618;
        color: $black;
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 5px;
          width: 5px;
          background: $green;
          border-radius: 200px; } } }

    .section-main-paragraph {
      line-height: 1.618;
      font-size: 14px;
      max-width: 750px;
      text-align: justify; } } }
