.button {
  border-radius: 200px;
  text-transform: lowercase;
  box-shadow: nth($shadows, 2);
  padding: 1.5em 1.75em;
  transition: 200ms ease-in-out all;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  outline: none;
  border: none;
  &:hover {
    box-shadow: nth($shadows, 4);
    transform: translateY(-3px); }
  &:active,
  &:focus {
    outline: none;
    border: none;
    box-shadow: nth($shadows, 2); }
  &.main-btn,
  &.outlined-btn {
    min-width: 150px; }
  &.main-btn {
    font-size: 18px;
    font-weight: 700;
    border: 3px solid $primary; }
  &.outlined-btn {
    font-size: 18px;
    font-weight: 700;
    background: transparent;
    border: 3px solid $white;
    color: $white;

    &.primary {
      box-shadow: none;
      border-color: $primary;
      color: $primary; } } }
