header {
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1000000;
  height: 75px;

  .main-nav-bar {
    background: transparent;
    height: 100%;
    .navbar-brand {
      .navbar-burger {
        background: none;
        border: none;
        box-shadow: none;
        &:hover {
          transform: none; }
        & > span {
          background: white;
          width: 20px;
          height: 2px; } }
      a.navbar-item {
        padding: 0;
        justify-content: flex-start;
        &:focus {
          outline: none; }
        &:hover {
          background: none; }
        &:active {
          background: none; } } }
    .navbar-end {
      .seperator {
        .seperator-item {
          color: #fbfbfb;
          font-weight: 700;
          margin-top: 4px; } }
      .languages {
        margin-top: 5px;
        .language {
          opacity: .75;
          color: #fbfbfb;
          &:hover {
            cursor: pointer; }
          &.is-active {
            opacity: 1;
            font-weight: 700; } } }
      a.navbar-item {
        color: $white;
        text-transform: lowercase;
        &.is-active {
          font-weight: 700;
          background: transparentize($white, 0.75);
          border-radius: 5px; } } }

    &.is-subpage {
      .navbar-end {
        a.navbar-item {
          color: $white; } } }

    @media screen and (max-width: 1023px) {
      .navbar-menu {
        transition: 200ms ease-in-out all;
        transform: translateY(-100%);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: block;
        visibility: hidden;
        .navbar-item.close-btn-container {
          display: flex;
          justify-content: flex-end;
          .close-menu-btn {
            position: relative;
            width: 25px;
            height: 25px;
            & > span {
              width: 2px;
              height: 20px;
              background: $black;
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -50%;
              &:first-of-type {
                transform: rotate(45deg) translateX(0) translateY(0); }
              &:last-of-type {
                transform: rotate(-45deg) translateX(0) translateY(0); } } } }
        .navbar-end {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: calc(100% - 75px);
          .languages {
            margin-top: auto;
            .language {
              flex: 1;
              text-align: center;
              opacity: 1;
              color: $black; } }
          a.navbar-item {
            color: $black;
            text-align: center;
            margin: 1em 0;
            &.is-active {
              color: $green; } } }
        &.is-active {
          transform: translateY(0);
          visibility: visible; } } } }

  .company-logo {
    height: 45px;
    width: auto;
    max-height: calc(100% - 10px);
    margin: 0 1em; } }
